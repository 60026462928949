/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { forwardRef, useRef, useState } from "react";
import { PiUsers } from "react-icons/pi";
import InputText from "../input/InputText";
import InputSelect from "../input/InputSelect";
import InputSelect2 from "../input/InputSelect2";
import { IoCloseOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import useWaybill from "../../hooks/useWaybill";
import InputFile from "../input/InputFile";
import {
  createWaybillSchema,
  createWaybillSchemaTwo,
} from "../../utils/validationSchema/waybill";
import { httpClient, api } from "../../api/axios";
import SearchableDropdown2 from "../input/SearchableDropdown2";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const WaybillModal = ({
  model,
  closeModal,
  requestType,
  aggregatorList,
  recyclerList,
  getWaybills,
  userData,
  isAggregator,
  selectOptDefault,
  sourceOfPlastic,
  typeOfPlastic,
}) => {
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const { createNewWaybill, createNewWaybillAlte } = useWaybill();
  const { program } = useSelector((state) => state.auth);

  const initialValues = {
    amount: requestType === "edit" ? userData.amount : "",
    quantity: requestType === "edit" ? userData.quantity : "",
    aggregatorId: requestType === "edit" ? userData.aggregatorId : "",
    recyclerId: requestType === "edit" ? userData.recyclerId : "",
    sourceOfPlastic: requestType === "edit" ? userData.sourceOfPlastic : "",
    typeOfPlastic: requestType === "edit" ? userData.typeOfPlastic : "",
    transactionDate: requestType === "edit" ? userData.transactionDate : "",
    file: null,
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (data?.file) {
        formData.append("amount", data.amount);
        formData.append("quantity", data.quantity);
        formData.append(
          "aggregatorId",
          isAggregator ? selectOptDefault?.value : data.aggregatorId
        );
        formData.append("programId", program);
        formData.append("recyclerId", data.recyclerId);
        formData.append("sourceOfPlasticId", data.sourceOfPlastic);
        formData.append("typeOfPlasticId", data.typeOfPlastic);
        formData.append("transactionDate", data.transactionDate);
        formData.append("file", data.file);
        const res = await createNewWaybill(formData);
        if (res.code === "00") {
          toast.success("Offtake created");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error creating Offtake");
        }
      }
      if (!data?.file) {
        formData.append("amount", data.amount);
        formData.append("quantity", data.quantity);
        formData.append(
          "aggregatorId",
          isAggregator ? selectOptDefault?.value : data.aggregatorId
        );
        formData.append("programId", program);
        formData.append("recyclerId", data.recyclerId);
        formData.append("sourceOfPlasticId", data.sourceOfPlastic);
        formData.append("typeOfPlasticId", data.typeOfPlastic);
        formData.append("transactionDate", data.transactionDate);
        const res = await createNewWaybillAlte(formData);
        if (res.code === "00") {
          toast.success("Offtake created");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error creating Offtake");
        }
      }
    } catch (error) {
      toast.error(error.message || "something went wrong");
    } finally {
      getWaybills();
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col">
      <label className={`after:content-['*'] after:text-red-500`}>
        Transaction Date
      </label>
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[140px] md:w-[200px] lg:w-[288px] p-2 rounded-md"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date"}</h3>
      </div>
    </div>
  ));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        isAggregator ? createWaybillSchemaTwo : createWaybillSchema
      }
      onSubmit={onSubmit}
    >
      {({ handleChange, errors, values, handleSubmit, setFieldValue }) => {
        return (
          <Form>
            <div>
              <div className="flex flex-col gap-y-2 md:w-[640px] lg:w-[640px] bg-white p-4 h-max">
                <div className="flex justify-between">
                  <PiUsers style={{ width: 26, height: 26 }} />
                  <IoCloseOutline
                    style={{ width: 26, height: 26, cursor: "pointer" }}
                    onClick={() => closeModal()}
                  />
                </div>
                <div className="">
                  <h1 className="capitalize font-bold">
                    {requestType === "edit"
                      ? "Edit Offtake"
                      : "Create new Offtake"}
                  </h1>
                  <p className="text-sm">Enter the details below</p>
                </div>
                <div className="flex justify-between gap-2">
                  <InputText
                    label={"Quantity(Kg)"}
                    placeholder={"Enter quantity"}
                    name="quantity"
                    value={values.quantity}
                    error={errors.quantity}
                    handleChange={handleChange}
                  />
                  <InputText
                    label={"Amount(NGN)"}
                    placeholder={"Enter amount"}
                    name="amount"
                    value={values.amount}
                    error={errors.amount}
                    handleChange={handleChange}
                  />
                </div>

                <div className="flex justify-between gap-2 w-full">
                  {!isAggregator ? (
                    <SearchableDropdown2
                      label={"Aggregator"}
                      options={aggregatorList}
                      error={errors.aggregatorId}
                      dropDownStyle={{
                        display: isAggregator ? "none" : "block",
                      }}
                      placeholder="Select  aggregator"
                      handleChange={(e) =>
                        setFieldValue("aggregatorId", e.value)
                      }
                    />
                  ) : (
                    ""
                  )}

                  <SearchableDropdown2
                    label={"Offatker"}
                    options={recyclerList}
                    error={errors.recyclerId}
                    className={`${isAggregator ? "w-full" : ""}`}
                    placeholder="Select offaker"
                    handleChange={(e) => setFieldValue("recyclerId", e.value)}
                  />
                </div>
                <div className="flex justify-between gap-2 w-full">
                  <SearchableDropdown2
                    label={"Type of Plastic"}
                    options={typeOfPlastic}
                    error={errors.typeOfPlastic}
                    placeholder="Select  Type of Plastic"
                    handleChange={(e) =>
                      setFieldValue("typeOfPlastic", e.value)
                    }
                  />

                  <SearchableDropdown2
                    label={"Source of Plastic"}
                    options={sourceOfPlastic}
                    error={errors.sourceOfPlastic}
                    placeholder="Select  Source of Plastic"
                    handleChange={(e) =>
                      setFieldValue("sourceOfPlastic", e.value)
                    }
                  />
                </div>
                <div className="flex flex-col">
                  <DatePicker
                    selected={
                      values.transactionDate
                        ? values.transactionDate
                        : startDate
                    }
                    onChange={(date) => {
                      setStartDate(date);
                      setFieldValue(
                        "transactionDate",
                        moment(date).format("YYYY-MM-DD")
                      );
                    }}
                    customInput={<ExampleCustomInput />}
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                  />
                  {/* {errors.dateOfBirth ? (
                      <p className="text-red-500">{errors.dateOfBirth}</p>
                    ) : (
                      ""
                    )} */}
                </div>

                <div className="w-full">
                  <InputFile
                    label="Upload Waybill"
                    error={errors.file}
                    handleChange={(e) =>
                      setFieldValue("file", e.target.files[0])
                    }
                  />
                </div>

                <br />

                <div className="flex gap-2 justify-center">
                  <button
                    className="mx-auto px-2 w-full h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272"
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={loading ? true : false}
                    onClick={handleSubmit}
                    className="bg-green-700 text-white flex justify-center items-center h-[40px] w-full gap-2"
                  >
                    {loading ? "Creating..." : "Create Offtake"}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
