import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useCallback,
} from "react";
import TopCard from "../../components/card/TopCard";
import CustomTable from "../../components/table/CustomTable";
import { GoPlus } from "react-icons/go";
import useOutsideClick from "../../hooks/useOutsideClick";
import Modal from "../../components/Modal";
import { WaybillModal } from "../../components/modal/WaybillModal";
import ViewDetail from "../../components/modal/ViewDetail";
import PaginationPane from "../../components/table/PaginationPane";
import useAggregator from "../../hooks/useAggregator";
import useRecycler from "../../hooks/useRecycler";
import useWaybill from "../../hooks/useWaybill";
import useResource from "../../hooks/useResource";
import { toMoney, toMoneyDecimal } from "../../utils/utils";
import SearchableDropdown from "../../components/input/SearchableDropdown";
import { MdDelete } from "react-icons/md";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { GoDownload } from "react-icons/go";

//date range
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useCollector from "../../hooks/useCollector";
import { getAllRecyclerList } from "../../ds/recycler";
import { useSelector } from "react-redux";
import DeleteModal from "../../components/modal/DeleteModal";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const headers = [
  "Offtaker",
  "Aggregators",
  "Amount",
  "Quantity(Kg)",
  "Transaction Date",
  "Entry Date",
];

const detail = {
  "first Name": "Jehoshe",
  "last Name": "Baidera",
  "phone Number": "080331485238",
  "email Address": "Jehoshebaidera@gmail.com",
  address: "Wuse zone 3 Near Access bank Abuja, Nigeria",
  state: "Sagamu",
  lga: "amu",
  "year of incorporation": "14 January 2024",
  dateCreated: "14 January 2024",
};
const Offtake = () => {
  const wrapperRef = useRef(null);
  const [query, setQuery] = useState("");

  const [showModal, setShowModal] = useOutsideClick(wrapperRef);
  const [viewDetail, setViewDetail] = useOutsideClick(wrapperRef);
  const [editDetail, setEditDetail] = useOutsideClick(wrapperRef);
  const [showDeleteModal, setShowDeleteModal] = useOutsideClick(wrapperRef);

  const [aggregatorList, setAggregatorList] = useState([]);
  const [aggId, setAggId] = useState(null);

  const [waybill, setWaybills] = useState([]);
  const [waybillDetail, setWaybillDetail] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 10;

  const [recyclerList, setRecyclerList] = useState([]);
  const [recycId, setRecycId] = useState(null);
  const [waybillId, setWaybillId] = useState({});
  const { program, user } = useSelector((state) => state.auth);

  const { gatAllAggregatorLists } = useAggregator();
  const { gatAllCollectorList } = useCollector();
  const { gatAllRecyclerLists } = useRecycler();
  const { loading, getAllWaybills, deleteOneWaybill, waybillDownload } =
    useWaybill();
  const { typeOfPlastic, sourceOfPlastic } = useResource(program);

  //for date range
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [formatStartDate, setFormatStartDate] = useState(null);
  const [formatEndDate, setFormatEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    {
      start
        ? setFormatStartDate(moment(start).format("YYYY-MM-DD"))
        : setFormatStartDate(null);
    }

    {
      end
        ? setFormatEndDate(moment(end).format("YYYY-MM-DD"))
        : setFormatEndDate(null);
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col gap-2">
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[240px] md:w-[170px] lg:w-[280px] p-2 rounded-md"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date range"}</h3>
      </div>
    </div>
  ));

  //fetch waybill
  const getWaybills = useCallback(
    async (newaggId, recId, formatStaDate, formatEdDate) => {
      const newEdDate = formatEndDate || formatEdDate;
      const newStaDate = formatStartDate || formatStaDate;
      const newrecId = recycId?.value || recId?.value;
      const newAgg = aggId?.value || newaggId?.value;
      const res = await getAllWaybills(
        page,
        limit,
        newAgg,
        newrecId,
        newStaDate,
        newEdDate,
        program
      );
      setTotalPages(res.data?.totalPages);
      setWaybills(res.data?.content);
    },
    [page, program]
  );
  useEffect(() => {
    getWaybills();
  }, [page, program, getWaybills]);

  //get lists
  useEffect(() => {
    const getAggregatorsList = async () => {
      const res = await gatAllAggregatorLists(program);
      const list = JSON.parse(res)?.data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setAggregatorList([...list]);
    };

    const getRecyclerList = async () => {
      const res = await gatAllRecyclerLists(program);
      const list = res?.data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setRecyclerList([...list]);
      // console.log(res.data);
    };
    getAggregatorsList();
    getRecyclerList();
  }, [program]);

  const handleDelete = (id) => {
    deleteOneWaybill(id);
    setTimeout(() => {
      getWaybills();
    }, 1000);
    toast.success("Deleted succesfully");
  };

  const reset = () => {
    setAggId(null);
    setRecycId(null);
    getWaybills();
  };

  const nextPage = () => {
    setPage((prev) => (prev >= totalPages ? 1 : prev + 1));
    getWaybills(aggId, recycId, formatStartDate, formatEndDate);
  };
  const prevPage = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
    getWaybills(aggId, recycId, formatStartDate, formatEndDate);
  };

  const attachmentArray = waybillDetail?.attachment?.split("/");
  const isAttachmentUrl = waybillDetail?.attachment?.startsWith("https");
  const isAggregator = user.userType === "AGGREGATOR";
  return (
    <div className="p-4">
      <div className="mb-10">
        <TopCard
          title={"Offtake"}
          subtitle={"List of waybill on the platform."}
          buttonTitle={"New Offtake"}
          Icon={GoPlus}
          setShowModal={() => setShowModal(true)}
          exportType="waybill"
          userType={user?.userType}
          downloadData={() =>
            waybillDownload({
              recyclerId: recycId?.value,
              aggregatorId: aggId?.value,
              startDate,
              endDate,
              program,
            })
          }
        />
      </div>

      <div className="mb-10 flex flex-col justify-between md:flex-row lg:flex-row gap-2">
        <div className="flex flex-wrap md:gap-2 gap-2">
          <SearchableDropdown
            options={aggregatorList || []}
            dropDownStyle={{ display: isAggregator ? "none" : "block" }}
            placeholder="Filter by aggregator"
            selectedOption={aggId}
            defaultValue={null}
            handleChange={(e) => {
              setAggId(e);
            }}
          />
          <SearchableDropdown
            options={recyclerList || []}
            placeholder="Filter by Offtaker"
            selectedOption={recycId}
            defaultValue={null}
            handleChange={(e) => {
              setRecycId(e);
            }}
          />
        </div>
        <div className="flex lg:flex-row lg:flex-nowrap gap-2">
          {/* date range*/}
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            customInput={<ExampleCustomInput />}
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            isClearable
          />

          {/* <InputSearch
            placeholder={"search"}
            inputValue={query}
            setInputValue={setQuery}
            css="w-[150px]"
          /> */}

          <button
            className="flex justify-center items-center h-[44px] w-[80px] lg:w-[101px] border border-gray-300 gap-2 rounded-md"
            onClick={() =>
              getWaybills(aggId, recycId, formatStartDate, formatEndDate)
            }
          >
            Apply
          </button>
          <button
            className="flex justify-center items-center h-[44px] w-[60px] border border-gray-300 gap-2 rounded-md"
            onClick={() => reset()}
          >
            refresh
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center">
          <p className="text-center">Loading...</p>
        </div>
      ) : waybill?.length === 0 ? (
        <div className="flex justify-center">
          <p className="text-center">No data.</p>
        </div>
      ) : (
        <CustomTable
          headers={
            user.userType !== "AGGREGATOR"
              ? headers
              : headers.filter((data) => data !== "Aggregators")
          }
          rows={
            user.userType !== "AGGREGATOR"
              ? waybill?.map((data, index) => {
                  return {
                    recycler: data.recycler ? data.recycler : "N/A",
                    aggregator: data.aggregator ? data.aggregator : "N/A",
                    amount: data.amount
                      ? "NGN " + toMoneyDecimal(data.amount)
                      : 0,
                    quantity: data.quantity ? toMoneyDecimal(data.quantity) : 0,
                    transactionDate: data.transactionDate
                      ? data?.transactionDate
                      : "N/A",
                    date: data.createdAt ? data.createdAt : 0,
                    open: (
                      <MdOutlineRemoveRedEye
                        key={index}
                        onClick={() => {
                          setViewDetail(true);
                          setWaybillDetail(data);
                        }}
                      />
                    ),
                    delete: user?.userType !== "PARTNER" && (
                      <MdDelete
                        key={index}
                        width={30}
                        onClick={() => {
                          setWaybillId(data.id);
                          setShowDeleteModal(true);
                        }}
                      />
                    ),
                  };
                })
              : waybill?.map((data, index) => {
                  return {
                    recycler: data.recycler ? data.recycler : "N/A",
                    amount: data.amount ? toMoney(data.amount) : 0,
                    quantity: data.quantity ? toMoneyDecimal(data.quantity) : 0,
                    transactionDate: data.transactionDate
                      ? data?.transactionDate
                      : "N/A",
                    date: data.createdAt ? data.createdAt : 0,
                    open: (
                      <MdOutlineRemoveRedEye
                        key={index}
                        onClick={() => {
                          setViewDetail(true);
                          setWaybillDetail(data);
                        }}
                      />
                    ),
                    delete: user?.userType !== "PARTNER" && (
                      <MdDelete
                        key={index}
                        width={30}
                        onClick={() => {
                          setWaybillId(data.id);
                          setShowDeleteModal(true);
                        }}
                      />
                    ),
                  };
                })
          }
        />
      )}
      <PaginationPane
        currentPage={page > 1 ? page : 1}
        totalPages={totalPages || 1}
        nextPage={() => nextPage()}
        prevPage={() => prevPage()}
      />
      {showModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowModal(false)}
        >
          <WaybillModal
            closeModal={() => setShowModal(false)}
            aggregatorList={aggregatorList}
            recyclerList={recyclerList}
            getWaybills={getWaybills}
            isAggregator={isAggregator}
            selectOptDefault={
              isAggregator
                ? { label: user.name, value: user?.aggregatorId }
                : {
                    label: waybillDetail.aggregator,
                    value: waybillDetail.aggregatorId,
                  }
            }
            sourceOfPlastic={sourceOfPlastic.map((data) => {
              return {
                label: data.name,
                value: data.id,
              };
            })}
            typeOfPlastic={typeOfPlastic.map((data) => {
              return {
                label: data.name,
                value: data.id,
              };
            })}
          />
        </Modal>
      )}
      {viewDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setViewDetail(false)}
        >
          <ViewDetail
            detail={{
              aggregator: waybillDetail.aggregator
                ? waybillDetail.aggregator
                : "N/A",
              recycler: waybillDetail.recycler ? waybillDetail.recycler : "N/A",
              "quantity(Kg)": waybillDetail.quantity
                ? toMoney(waybillDetail.quantity || 0)
                : 0,
              "amount(NGN)": waybillDetail.amount
                ? toMoney(waybillDetail.amount || 0)
                : 0,
              "Source Of Plastic": waybillDetail.sourceOfPlastic
                ? waybillDetail.sourceOfPlastic
                : "N/A",
              "Type Of Plastic": waybillDetail.typeOfPlastic
                ? waybillDetail.typeOfPlastic
                : "N/A",
              attachment: (
                <>
                  {attachmentArray
                    ? attachmentArray[attachmentArray?.length - 1]
                    : ""}{" "}
                  {isAttachmentUrl ? (
                    <a
                      className="bg-[#079455] text-white w-[128px]  p-2 rounded-3xl flex gap-1 items-center justify-center"
                      href={waybillDetail.attachment}
                    >
                      <GoDownload /> Download
                    </a>
                  ) : (
                    <a
                      className="bg-[#079455] text-white w-[128px]  p-2 rounded-3xl flex gap-1 items-center justify-center"
                      style={{ cursor: "default", pointerEvents: "none" }}
                      href={waybillDetail.attachment}
                    >
                      <GoDownload /> Download
                    </a>
                  )}
                </>
              ),
            }}
            closeModal={() => setViewDetail(false)}
            title={"Offtake Details"}
            subtitle={"Offtake details below"}
            dateCreated={"14 January 2024"}
            editbutton={true}
            createdAt={waybillDetail.createdAt}
            sourceOfPlastic={sourceOfPlastic.map((data) => {
              return {
                label: data,
                value: data,
              };
            })}
            typeOfPlastic={typeOfPlastic.map((data) => {
              return {
                label: data,
                value: data,
              };
            })}
          />
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowDeleteModal(false)}
        >
          <DeleteModal
            closeModal={() => setShowDeleteModal(false)}
            handleDelete={() => handleDelete(waybillId)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Offtake;
