/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect, forwardRef } from "react";
import { PiUsers } from "react-icons/pi";
import InputText from "../input/InputText";
import { IoCloseOutline } from "react-icons/io5";
import useRecycler from "../../hooks/useRecycler";
import { getState } from "../../ds/resource";
import toast from "react-hot-toast";
import SearchableDropdown2 from "../input/SearchableDropdown2";
import { Form, Formik } from "formik";
import { createRecyclerSchema } from "../../utils/validationSchema/recycler";
import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const RecyclerModal = ({
  states,
  closeModal,
  requestType,
  getRecyclers,
  userData,
}) => {
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const { createNewRecycler, updateRecyclerDetail } = useRecycler();
  const [selectedState, setSelectedState] = useState("");
  const { program } = useSelector((state) => state.auth);

  const initialValues = {
    name: requestType === "edit" ? userData.name : "",
    address: requestType === "edit" ? userData.address : "",
    state: requestType === "edit" ? userData.state : "",
    email: requestType === "edit" ? userData.email : "",
    phoneNumber: requestType === "edit" ? userData.phoneNumber : "",
    contactPersonFirstName:
      requestType === "edit" ? userData.contactPersonFirstName : "",
    contactPersonLastName:
      requestType === "edit" ? userData.contactPersonLastName : "",
    enrollmentDate: requestType === "edit" ? userData.enrollmentDate : "",
  };

  const onSubmit = async (data) => {
    try {
      if (requestType === "edit") {
        setLoading(true);
        const res = await updateRecyclerDetail({
          ...data,
          id: userData.id,
          programId: program,
        });
        if (res.code === "00") {
          toast.success("Offaker updated");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error updating Offaker");
        }
      }
      if (requestType !== "edit") {
        setLoading(true);
        const res = await createNewRecycler({ ...data, programId: program });
        if (res.code === "00") {
          toast.success("Offaker Created");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error creating Offaker");
        }
      }
    } catch (error) {
      toast.error(error.message || "something went wrong");
    } finally {
      setLoading(false);
      getRecyclers();
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col gap-2">
      <label className={`after:content-['*'] after:text-red-500`}>
        Enrollment Date
      </label>
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[140px] md:w-[200px] lg:w-[280px] p-2  rounded-md cursor:pointer"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date"}</h3>
      </div>
    </div>
  ));

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={createRecyclerSchema}
      onSubmit={onSubmit}
    >
      {({ handleChange, errors, values, handleSubmit, setFieldValue }) => {
        return (
          <Form>
            <div>
              <div className="flex flex-col gap-y-2 md:w-[640px] lg:w-[640px] bg-white p-4 ">
                <div className="flex justify-between">
                  <PiUsers style={{ width: 26, height: 26 }} />
                  <IoCloseOutline
                    style={{ width: 26, height: 26, cursor: "pointer" }}
                    onClick={() => closeModal()}
                  />
                </div>
                <div className="">
                  <h1 className="capitalize font-bold">
                    {requestType === "edit"
                      ? "Edit Offaker"
                      : "Create new Offaker"}
                  </h1>
                  <p className="text-sm">Enter the details below</p>
                </div>
                <div className="flex justify-between gap-x-2">
                  <InputText
                    label={"Business Name"}
                    placeholder={"Enter business name"}
                    name="name"
                    value={values.name}
                    css="w-full"
                    handleChange={handleChange}
                  />

                  <InputText
                    label={"Business Address"}
                    placeholder={"Enter business address"}
                    name="address"
                    value={values.address}
                    handleChange={handleChange}
                  />
                </div>

                <div className="flex justify-between gap-x-2">
                  <InputText
                    label={"Contact Person First Name"}
                    placeholder={"Enter contact person first name"}
                    name="contactPersonFirstName"
                    value={values.contactPersonFirstName}
                    handleChange={handleChange}
                  />
                  <InputText
                    label={"Contact Person Last Name"}
                    placeholder={"Enter contact person last name"}
                    name="contactPersonLastName"
                    value={values.contactPersonLastName}
                    handleChange={handleChange}
                  />
                </div>
                <div className="flex justify-between gap-x-2">
                  <InputText
                    label={"Phone Number"}
                    placeholder={"Enter phone number"}
                    name="phoneNumber"
                    value={values.phoneNumber}
                    handleChange={handleChange}
                  />

                  <InputText
                    label={"Email Address"}
                    placeholder={"Enter email address"}
                    name="email"
                    value={values.email}
                    handleChange={handleChange}
                  />
                </div>
                <div className="flex justify-between w-full">
                  <SearchableDropdown2
                    label={"State"}
                    options={states}
                    placeholder="Select state"
                    defaultInputValue={values.state}
                    handleChange={(selectionOption) =>
                      setFieldValue("state", selectionOption.label)
                    }
                  />
                  <div className="flex flex-col">
                    <DatePicker
                      selected={
                        values.enrollmentDate
                          ? values.enrollmentDate
                          : startDate
                      }
                      onChange={(date) => {
                        setStartDate(date);
                        setFieldValue(
                          "enrollmentDate",
                          moment(date).format("YYYY-MM-DD")
                        );
                      }}
                      customInput={<ExampleCustomInput />}
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                    />
                    {/* {errors.dateOfBirth ? (
                      <p className="text-red-500">{errors.dateOfBirth}</p>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <br />

                <div className="flex gap-2 justify-center">
                  <button
                    className="mx-auto px-2 w-full h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272"
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </button>
                  {requestType === "edit" ? (
                    <button
                      type="submit"
                      disabled={loading ? true : false}
                      onClick={() => handleSubmit()}
                      className="bg-green-700 text-white flex justify-center items-center h-[40px] w-full gap-2"
                    >
                      {loading ? "Updating..." : "Save changes"}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled={loading ? true : false}
                      onClick={handleSubmit}
                      className="bg-green-700 text-white flex justify-center items-center h-[40px] w-full gap-2"
                    >
                      {loading ? "Creating..." : "Create Offaker"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
